/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, { useState } from 'react';
import { Box, Flex, Text, Link, Image } from '@chakra-ui/react';
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import * as Icons from '../../../assets';
import ErrorReport from './ErrorReport';

type CSVImportToastProps = {
  newUsers?: any;
  errorUsers?: any;
  errors?: any;
  onClose: () => void;
  setErrors?: any;
  isToastOpen?: boolean;
  setIsToastOpen?: any;
  type: string;
  existingUsers?: number;
  updatedUsers?: number;
};

function closeIcon(onClose) {
  return (
    <>
      <CloseIcon
        boxSize={3}
        color="grey.500"
        sx={{ marginTop: '10px', marginLeft: '420px', marginRight: '10px', fontWeight: '520px' }}
        onClick={onClose}
      />
    </>
  );
}

const CSVImportToast = ({
  onClose,
  newUsers,
  errorUsers,
  errors,
  setErrors,
  setIsToastOpen,
  type,
  existingUsers = 0,
  updatedUsers = 0,
}: CSVImportToastProps) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleSeeReportClick = () => {
    setErrors(errors || []);
    setIsErrorModalOpen(true);
    setIsToastOpen(false);
  };

  if (newUsers > 0 && type === 'newUsers') {
    return (
      <Box
        sx={{
          background: 'white',
          color: 'black',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          padding: '2px 1px 12px 2px',
          borderRadius: '5px',
        }}
      >
        {closeIcon(onClose)}
        <Flex
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23.52px',
            marginLeft: '8px',
            color: 'rgba(44, 44, 46, 1)',
          }}
        >
          <CheckCircleIcon boxSize={5} sx={{ marginTop: '5px', marginLeft: '5px', color: 'rgba(0, 202, 20, 1)' }} />
          <Box
            sx={{
              font: 'Helvetica',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '23.52px',
              marginLeft: '10px',
              color: 'rgba(44, 44, 46, 1)',
            }}
          >
            <Text>CSV Import Successful</Text>
          </Box>
        </Flex>
        <Box
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '12px',
            marginLeft: '42px',
            marginBottom: '5px',
            marginTop: '8px',
            lineHeight: '20.58px',
            color: 'rgba(44, 44, 46, 0.6)',
          }}
        >
          {`${newUsers} user${newUsers > 1 ? 's' : ''} created.`}
        </Box>
      </Box>
    );
  }

  if (updatedUsers > 0 && type === 'updatedUsers') {
    return (
      <Box
        sx={{
          background: 'white',
          color: 'black',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          padding: '2px 1px 12px 2px',
          borderRadius: '5px',
        }}
      >
        {closeIcon(onClose)}
        <Flex
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23.52px',
            marginLeft: '8px',
            color: 'rgba(44, 44, 46, 1)',
          }}
        >
          <CheckCircleIcon boxSize={5} sx={{ marginTop: '5px', marginLeft: '5px', color: 'rgba(0, 202, 20, 1)' }} />
          <Box
            sx={{
              font: 'Helvetica',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '23.52px',
              marginLeft: '10px',
              color: 'rgba(44, 44, 46, 1)',
            }}
          >
            <Text>CSV Import Successful</Text>
          </Box>
        </Flex>
        <Box
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '12px',
            marginLeft: '42px',
            marginBottom: '5px',
            marginTop: '8px',
            lineHeight: '20.58px',
            color: 'rgba(44, 44, 46, 0.6)',
          }}
        >
          {`${updatedUsers} user${updatedUsers > 1 ? 's' : ''} updated.`}
        </Box>
      </Box>
    );
  }

  if (existingUsers > 0 && type === 'existingUsers') {
    return (
      <Box
        sx={{
          background: 'white',
          color: 'black',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          padding: '2px 1px 12px 2px',
          borderRadius: '5px',
        }}
      >
        {closeIcon(onClose)}
        <Flex
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23.52px',
            marginLeft: '8px',
            color: 'rgba(44, 44, 46, 1)',
          }}
        >
          <Image src={Icons.csvFailure} loading="lazy" />
          <Box
            sx={{
              font: 'Helvetica',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '23.52px',
              marginLeft: '10px',
              color: 'rgba(44, 44, 46, 1)',
            }}
          >
            <Text>CSV Import failed</Text>
          </Box>
        </Flex>
        <Box
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '12px',
            marginLeft: '42px',
            marginBottom: '5px',
            marginTop: '8px',
            lineHeight: '20.58px',
            color: 'rgba(44, 44, 46, 0.6)',
          }}
        >
          {`${existingUsers} user${existingUsers > 1 ? `s` : ``} already exist${existingUsers === 1 ? `s` : ``}.`}
        </Box>
      </Box>
    );
  }

  if (errorUsers > 0 && type === 'errorUsers') {
    return (
      <Box
        sx={{
          background: 'white',
          color: 'black',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          padding: '2px 1px 12px 2px',
          borderRadius: '5px',
        }}
      >
        {closeIcon(onClose)}
        <Flex
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23.52px',
            marginLeft: '8px',
            color: 'rgba(44, 44, 46, 1)',
          }}
        >
          <Image src={Icons.csvFailure} loading="lazy" />
          <Box
            sx={{
              font: 'Helvetica',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '23.52px',
              marginLeft: '10px',
              color: 'rgba(44, 44, 46, 1)',
            }}
          >
            <Text>CSV Import Failed</Text>
          </Box>
        </Flex>
        <Box
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '12px',
            marginLeft: '42px',
            marginBottom: '5px',
            marginTop: '8px',
            lineHeight: '20.58px',
            color: 'rgba(44, 44, 46, 0.6)',
          }}
        >
          {`${errorUsers || 0} user${existingUsers > 1 ? `s` : ``} encountered errors. Click `}
          <Link
            sx={{ color: 'rgba(0, 122, 255, 1)', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleSeeReportClick}
          >
            see report
          </Link>
          {' to view errors.'}
        </Box>
        {isErrorModalOpen && (
          <ErrorReport errors={errors} isErrorModalOpen={isErrorModalOpen} setIsErrorModalOpen={setIsErrorModalOpen} />
        )}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          background: 'white',
          color: 'black',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          padding: '2px 1px 12px 2px',
          borderRadius: '5px',
        }}
      >
        {closeIcon(onClose)}
        <Flex
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23.52px',
            marginLeft: '8px',
            color: 'rgba(44, 44, 46, 1)',
          }}
        >
          <Image src={Icons.csvFailure} loading="lazy" />
          <Box
            sx={{
              font: 'Helvetica',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '23.52px',
              marginLeft: '10px',
              color: 'rgba(44, 44, 46, 1)',
            }}
          >
            <Text>CSV Import Failed</Text>
          </Box>
        </Flex>
        <Box
          sx={{
            font: 'Helvetica',
            fontWeight: '500',
            fontSize: '12px',
            marginLeft: '42px',
            marginBottom: '5px',
            marginTop: '8px',
            lineHeight: '20.58px',
            color: 'rgba(44, 44, 46, 0.6)',
          }}
        >
          {`Error importing CSV: ${errors.message || 'Unknown error'}`}
        </Box>
      </Box>
    );
  }
};

export default CSVImportToast;
