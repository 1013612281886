import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import DownloadCSV from '../../services/api/downloadCSV';
import ImportCSVButton from './ImportCSVButton';
import { UsersContext } from './UserList';

const downloadCSV = new DownloadCSV();

const AddCsvUsers = ({ isOpen, onClose }) => {
  const { setIsLoading } = useContext(UsersContext);

  const handleDownloadClick = async () => {
    try {
      setIsLoading(true);
      const data = await downloadCSV.template().get();
      const csvFile = new Blob([data], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = 'users_import_template.csv';
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error = ', error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Users from .CSV</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              as="b"
              sx={{
                fontFamily: 'Helvetica',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20.58px',
                letterSpacing: '0.23px',
                color: '#2C2C2E',
              }}
            >
              Any updates on CSV will override current field for all users.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              sx={{
                color: '#007AFF',
                background: 'white',
                border: '1px solid #007AFF',
                height: '38px',
                marginLeft: '2px',
                width: '250px',
              }}
              mr={4}
              onClick={handleDownloadClick}
            >
              Download CSV Template
            </Button>
            <ImportCSVButton onClose={onClose} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCsvUsers;
