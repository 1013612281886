/* eslint-disable max-lines-per-function */
import { useNavigate } from 'react-router-dom';
import { Button, useToast } from '@chakra-ui/react';
import CSVImporter from '../../services/api/csvImport';
import eventBus from '../../utils/EventBus';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CSVImportToast from './CSVImportToast';
import { UsersContext } from './UserList';

const ImportCSVButton = ({ onClose }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const { setIsLoading } = useContext(UsersContext);

  const handleCSVImport = async ({ detail }) => {
    if (detail.input && detail.input.files[0]) {
      const formData = new FormData();
      formData.append('file', detail.input.files[0]);

      const importer = new CSVImporter();

      try {
        setIsLoading(true);
        const { data } = await importer.process(formData);
        const newUsers = data['New users'];
        const errorUsers = data['Error users'];
        const errorsMessages = data['Error messages'] ?? [];
        const existingUsers = data['Existing users'] ?? 0;
        const updatedUsers = data['Updated users'] ?? 0;
        onClose();
        setIsLoading(false);
        navigate('/');
        if (newUsers > 0) {
          toast({
            render: ({ onClose }) => (
              <CSVImportToast
                onClose={onClose}
                newUsers={newUsers}
                errorUsers={errorUsers}
                errors={errorsMessages}
                setErrors={setErrors}
                type={'newUsers'}
              />
            ),
            status: 'success',
            position: 'top-right',
            duration: 8000,
            isClosable: true,
          });
        }
        if (errorsMessages?.length > 0) {
          toast({
            render: ({ onClose }) => (
              <CSVImportToast
                onClose={onClose}
                newUsers={newUsers}
                errorUsers={errorUsers}
                errors={errorsMessages}
                setErrors={setErrors}
                isToastOpen={isToastOpen}
                setIsToastOpen={setIsToastOpen}
                type={'errorUsers'}
              />
            ),
            status: 'error',
            position: 'top-right',
            duration: 8000,
            isClosable: true,
          });
        }
        if (existingUsers > 0) {
          toast({
            render: ({ onClose }) => (
              <CSVImportToast
                existingUsers={existingUsers}
                onClose={onClose}
                newUsers={newUsers}
                errorUsers={errorUsers}
                errors={errorsMessages}
                setErrors={setErrors}
                isToastOpen={isToastOpen}
                setIsToastOpen={setIsToastOpen}
                type={'existingUsers'}
              />
            ),
            status: 'error',
            position: 'top-right',
            duration: 8000,
            isClosable: true,
          });
        }
        if (updatedUsers > 0) {
          toast({
            render: ({ onClose }) => (
              <CSVImportToast
                existingUsers={existingUsers}
                onClose={onClose}
                newUsers={newUsers}
                errorUsers={errorUsers}
                errors={errorsMessages}
                setErrors={setErrors}
                isToastOpen={isToastOpen}
                setIsToastOpen={setIsToastOpen}
                type={'updatedUsers'}
                updatedUsers={updatedUsers}
              />
            ),
            status: 'success',
            position: 'top-right',
            duration: 8000,
            isClosable: true,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error importing CSV:', error);
        setIsLoading(false);
        toast({
          render: ({ onClose }) => <CSVImportToast onClose={onClose} errors={errors} setErrors={setErrors} type={''} />,
          status: 'error',
          position: 'top-right',
          duration: 8000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    eventBus.registerOnce('IMPORT_CSV', handleCSVImport);
    return () => {
      eventBus.remove('IMPORT_CSV', handleCSVImport);
    };
  }, [toast]);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      eventBus.fire('IMPORT_CSV', { input: event.target });
    }
  };

  return (
    <>
      <input type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputFileRef} />
      <Button
        sx={{ color: 'white', background: '#007AFF', left: '10px', height: '38px', width: '250px' }}
        mr={4}
        onClick={() => {
          setIsToastOpen(true);
          inputFileRef.current.click();
        }}
      >
        Import CSV
      </Button>
    </>
  );
};

export default ImportCSVButton;
