const APIService = require('../APIService');
const urlParams = require('../../../utils/urlParams');

class UserGroups extends APIService {
  constructor(id) {
    super();
    this._url = `/access-control/user-categories${id ? `/${id}` : ''}/?limit=1000`;
  }

  async get({ parameters }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}${urlParams(parameters)}`);
      return data.results;
    } catch (error) {
      throw new Error(error);
    }
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, payload);
      return data;
    } catch (error) {
      throw new Error(error.response?.data.name || error);
    }
  }

  async patch({ id, payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${id}`, payload);
      return data;
    } catch (error) {
      throw new Error(error.response?.data.name || error);
    }
  }

  async delete({ id }) {
    try {
      const { data } = await this.httpClient.delete(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async enableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/enable-all`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async disableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/disable-all`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getInsights({ id }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}/${id}/insights`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateInsights({ id, payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}/${id}/insights`, payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = UserGroups;
